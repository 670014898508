import React from "react";

export default class FOOTER extends React.Component{
    render(){
        return(
            <footer className="landing-footer">

            <div className="footer-img">
             <div className="container-fluid text-center text-md-left ">
            
               <div className="row">
            
                 <div className="col-md-2 mx-auto ">
   
                   <h5 className="footer-title mt-3 mb-4  text-center text-md-left"><img src="../Img/footer-logo.svg" alt=""/></h5>
   
                 </div>
            
              
   
                          <div className="col-md-2 mx-auto">
   
            
                   <h5 className="footer-title mt-3 mb-4 ">Useful Links</h5>
   
                   <ul className="list-unstyled">
                     <li>
                       <a href="#!">Home</a>
                     </li>
                     <li>
                       <a href="#!">About</a>
                     </li>
                     <li>
                       <a href="#!">Services</a>
                     </li>
                     <li>
                       <a href="#!">Contact</a>
                     </li>
                   </ul>
   
                 </div>
            
          
   <div className="col-md-2 mx-auto ">
     <h5 className="footer-title mt-3 mb-4 ">Others</h5>
     <ul className="list-unstyled">
       <li>
         <a href="#!">Blog</a>
       </li>
       <li>
         <a href="#!">Live Agent</a>
       </li>
     </ul>
   </div>
   
                 
                 <div className="col-md-2 mx-auto"> 
                   <h5 className="footer-title mt-3 mb-4">Support</h5>
                   <ul className="list-unstyled">
                     <li>
                       <a href="#!">Help center</a>
                     </li>
                     <li>
                       <a href="#!">Terms of Service</a>
                     </li>
                     <li>
                       <a href="#!">Legal</a>
                     </li>
                     <li>
                       <a href="#!">Privacy policy</a>
                     </li>
                   </ul>
   
                 </div>
                 
   
                 <div className="col-xl-4 text-center ml-auto">
                 <form className="form-inline mt-3">
               <div className="form-group mx-auto">
                   <label htmlFor=""></label>
                   <input type="text" name="" id="" className=" search-input" placeholder="Suscribe to our news letter" />
                  <button className=" search-btn btn text-white">Subscribe <span className="pl-2"><img src="../Img/send.svg" alt=""/> </span></button>
               </div>
           </form>
                 </div>
               </div>
           </div> 
   
             </div>
            
            
   
   
   
           </footer>
        )
    }
}