import React from "react"
import "./post.css"
import Sidebar from "../Sidebar/side"
//import Navbar from "../Navbar/nav"
export default class Post extends React.Component{
    render(){
        return(
            <div>    
                <main className="row-section">
                   <div className="side">
                       <Sidebar/>
                   </div>
                    <div className="main">
                     <div className="mainbox pb-5">
                     <div className="sub-title">
                            <h5>Balanced Diet</h5>
                            <p>Published on Jun 3rd, 2020 02:25 PM</p>
                        </div>
                        <div className="post-card">
                       <div className="card text-left post-content-card">
                           <div className="row ml-0 mr-0">
                               <div className="post-author-img ">
                                   <img src="./Img/nurse.png" alt=""/>
                               </div>
                               <div className="post-detail-body col ">
                                   <p className="post-author-name">Perry Tyler</p>
                                   <div className="main-post">
                                       <h6 className="post-content-title">Balanced Diet  <span className="hashtag">#diet #Daily Tips</span></h6>
                                       <img src="./Img/card-img.png" alt="" className="card-img"></img>
                                       <p className="post-main-body">
                                       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget risus id est congue congue. Sed eu venenatis sem. Vivamus ultricies imperdiet dui, ac blandit magna aliquet eu. Integer laoreet dui risus, eu facilis.
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget risus id est congue congue. Sed eu venenatis sem. Vivamus ultricies imperdiet dui, ac blandit magna aliquet eu. Integer laoreet dui risus, eu facilis.
<br/><br/>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget risus id est congue congue. Sed eu venenatis sem. Vivamus ultricies imperdiet dui, ac blandit magna aliquet eu. Integer laoreet dui risus, eu facilis.
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget risus id est congue congue. Sed eu venenatis sem. Vivamus ultricies imperdiet dui, ac blandit magna aliquet eu. Integer laoreet dui risus, eu faciliss.
                                       </p>
                                       <div className="d-flex justify-content-between align-items-center ml-0 mr-0 reaction">
                                           <div className="like-post">
                                               <img src="./Img/like.svg" alt=""/> 295
                                           </div>
                                           <div className="repost">
                                               <img src="./Img/cached.svg" alt=""/> 1072
                                           </div>
                                           <div className="comment">
                                               <img src="./Img/comment.svg" alt=""/> 200
                                           </div>
                                           </div>
                                           <div>
                                               <form className="form-group">
                                                 <label htmlFor=""></label>
                                                 <textarea className="form-control post-comment" name="" id="" rows="3" placeholder="Write a comment" ></textarea>
                                                 <button className="btn submit-comment">Comment</button>
                                               </form>
                                           </div>

                                   </div>
                                   <div className="comment-section mt-5 pt-3">
                                       <p className="comments">Comments</p>
                                     <div className="row ml-0 mr-0">
                                     <div className="comment-image">
                                         <img src="./Img/snoop.png" alt=""/>
                                     </div>
                                     <div className="comment-content col">
                                        <div><span className="comment-author">Snoop Dog</span> </div> 
                                        <div className="comment-body">Thanks i really learnt a lot now i know the foods to eat and not to eat.</div>
                                     </div>
                                     </div>
                                   </div>                     
                               </div>
                           </div>
                       </div>
                       </div>
                     </div>
                        </div>        
                </main>
            </div>
        )
    }
}