import React from "react"
import "./messages.css"
import Sidebar from "../Sidebar/side"
//import Navbar from "../Navbar/nav"
export default class Messages extends React.Component{
    render(){
        return(
            <div>    
                <main className="row-section">
                   <div className="side">
                       <Sidebar/>
                   </div>
                    <div className="main">
                     <div className="mainbox">
                         <h3>Messages</h3>
                     </div>
                        </div>        
                </main>
            </div>
        )
    }
}