import React from "react"

export default class FIND_A_DOC extends React.Component{
    render(){
        return(
            <div>
                    <div className="sidebox">
                    <div className="side-title">
                        Find a Doctor  <span className="float-right"><img src="./Img/find.svg" alt=""/> </span>
                    </div>
                    <div className="dp-line"></div>
                    <div className="sidebox-content">
                        <div className="appointment">
                            <span>Therapist</span> <button className="btn ">Book Appointment</button>
                        </div>
                        <div className="appointment">
                            <span>Pediatrician</span> <button className="btn">Book Appointment</button>
                        </div>
                        <div className="appointment">
                            <span>Obstetrician</span> <button className="btn">Book Appointment</button>
                        </div>
                        <div className="appointment">
                            <span>Surgeon</span> <button className="btn">Book Appointment</button>
                        </div>
                        <div className="appointment">
                            <span>Cardiologist</span> <button className="btn">Book Appointment</button>
                        </div>
                        <div className="appointment">
                            <span>Dermatologist</span> <button className="btn">Book Appointment</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}