import React from "react";
import "./nav.css";
import "./bottom.css"
// IMPORTING REACT ROUTER DOM
import { NavLink } from "react-router-dom";


const Navbar  = () => {
    return (
        <div>
      <header className="Header">
        <nav className="my-navbar home-nav pb-0 ">
       <a className="my-nav-logo mt-n3 " href="#!"><img src="../Img/logo.svg" alt=""/><span className="medhub  pt-5">MEDHUB</span></a>
    <div className="pt-2 ">
      <ul className="my-nav-list  ">
          <NavLink exact to="/home">
      <li className="my-nav-item mobile">
                <span className="my-nav-link" >Home</span>
            </li>
            </NavLink>
            <NavLink exact to="/explore">
            <li className="my-nav-item mobile">
                <span className="my-nav-link">Explore</span>
            </li>
            </NavLink>
            <NavLink exact to="/trends">
            <li className="my-nav-item mobile">
                <span className="my-nav-link" >Trends </span>
            </li>
           </NavLink>
            <li className="my-nav-item ">
                <a href="#!" ><img src="./Img/plus.svg" alt=""/> </a>
            </li>
            <NavLink exact to="/notifications">
            <li className="my-nav-item mobile">
                <span><img src="./Img/bell.svg" alt=""/> </span>
            </li>
            </NavLink>
            <li className="my-nav-item">
                <a href="#!"><img src="./Img/dp.png" alt="" className="nav-doc" /> </a>
            </li>
            <li className='my-nav-item'>
            <div className="dropdown">
                 <button className="dropbtn "><img src="../Img/arrow-down.svg" alt=""/></button>
                  <div className="dropdown-content">
                  <div className="row ml-0 mr-0 min-profile">
                      <div className="dp mt-n1">
                          <img src="./Img/dp.png" alt=""></img>
                      </div>
                      <div className="dp-detail  pl-2 mt-1">
                          <p className="dp-name">John Doe</p>
                          <p className="dp-email">johndoe@email.com</p>
                      </div>
                  </div>
                   <div className="dp-line"></div>
                   <div className="dp-nav">
                 <NavLink to="/explore" className="dp-nav-item btn not-mobile"> Explore <span className="float-right"><img src="./Img/arrow_right.svg" alt=""/> </span> </NavLink>
                     <NavLink to="/trends" className="dp-nav-item btn not-mobile">Trends <span className="float-right"><img src="./Img/arrow_right.svg" alt=""/> </span> </NavLink>
                     < NavLink to="/messages" className="dp-nav-item btn not-mobile">Messages<span className="float-right"><img src="./Img/arrow_right.svg" alt=""/> </span> </ NavLink>
                     <button className="dp-nav-item btn">Profile <span className="float-right"><img src="./Img/arrow_right.svg" alt=""/> </span>  </button>
                     <button className="dp-nav-item btn">Logout <span className="float-right"><img src="./Img/arrow_right.svg" alt=""/> </span> </button>
                   </div>
            </div>
            </div>
            </li>
      </ul>
    </div>
        </nav>
      </header>
      <div className="message-icon">
                        <svg viewBox="0 0 56 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M55.1839 15.0479V34.3189C55.1839 36.669 52.7373 38.5918 49.7471 38.5918H6.25285C3.26262 38.5918 0.816071 36.669 0.816071 34.3189V8.68125C0.816071 6.33113 3.26262 4.40831 6.25285 4.40831H33.7086C33.5455 5.09198 33.4368 5.81838 33.4368 6.54478C33.4368 9.70675 35.2037 12.5055 37.9765 14.4711L28 19.3636L6.25285 8.68125V12.9542L28 23.6365L42.4075 16.5435C43.8754 16.9707 45.3977 17.2271 47.0287 17.2271C50.1005 17.2271 52.9005 16.3939 55.1839 15.0479ZM38.8736 6.54478C38.8736 10.0913 42.5162 12.9542 47.0287 12.9542C51.5413 12.9542 55.1839 10.0913 55.1839 6.54478C55.1839 2.99824 51.5413 0.135376 47.0287 0.135376C42.5162 0.135376 38.8736 2.99824 38.8736 6.54478Z" className="message" />
</svg>

                        </div>
     <div className="bottom-nav">
         <NavLink to="/find-a-doc" exact>
             <img src="./Img/find.svg" alt="" width="38px" />
             </NavLink>
        <NavLink to="/categories" exact>
       <img src="./Img/categories.svg" alt="" />
       </NavLink>
         <NavLink to="/home" exact>
             <img src="./Img/home.svg" alt=""/>
             </NavLink>
      <NavLink to="/suggestion" exact>
             <img src="./Img/suggestion.svg" alt="" height="32px" />
             </NavLink>
    <NavLink to="/notifications" exact>
             <img src="./Img/notification.svg" alt=""/>
             </NavLink>
     </div>
      </div>
    );
  };
  
  export default Navbar
  