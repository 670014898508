import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import App from "./App"
import LandingPage from "./Components/Landing/landing"

export default class Application extends Component{
    render(){
        return(
            <div>
                <Switch>
                    <Route exact path="/" component={LandingPage}/>
                    <App/>
                </Switch>

            </div>
        )
    }
}