import './App.css';
import React from "react"

// IMPORTING REACT ROUTER DOM
import {  Switch, Route } from "react-router-dom";
import Navbar from "./Components/DASHBOARD/Navbar/nav"
//
import Home from "./Components/DASHBOARD/Home/home"
import  Categories from "./Components/DASHBOARD/Categories/categories"
import FIND_A_DOC from "./Components/DASHBOARD/Find_doc/find"
import Suggestion from "./Components/DASHBOARD/Suggestions/suggestion"
import Messages from "./Components/DASHBOARD/Messages/messages"
import Notifications from './Components/DASHBOARD/Notifications/notification';
import Post from './Components/DASHBOARD/Post/post';
import Trends from './Components/DASHBOARD/Trends/trends';
import Explore from './Components/DASHBOARD/Explore/explore';


class App extends React.Component{
    render(){
        return(
            <div className="App">
                <Navbar/>
                   <Switch>    
                       <Route exact path="/home" component={Home} />
                       <Route exact path="/categories" component={Categories}/>
                       <Route exact path="/find-a-doc" component={FIND_A_DOC}/>
                       <Route exact path="/suggestion" component={Suggestion}/>
                       <Route exact path="/messages" component={Messages}/>
                       <Route exact path="/notifications" component={Notifications}/>
                       <Route exact path="/post" component={Post}/>
                       <Route exact path="/trends" component={Trends}/>
                       <Route exact path="/explore" component={Explore}/>
                   </Switch>
            </div>
        )
    }
}

export default App;