import React from "react"
import Carousel from 'react-elastic-carousel'
import "./landing.css"
import "./landing-resp.css"

export default class Testimonial extends React.Component {
    constructor(props) {
      super(props)
      this.breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 768, itemsToShow: 2, itemsToScroll: 2, pagination: false },
        { width: 850, itemsToShow: 3 },
        { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 },
      ]
    }
    render() {
      return (
        <Carousel breakPoints={this.breakPoints} enableAutoPlay autoPlaySpeed={2500}>
                         <div className="test-card">
                              <div className="test-content">
                                <div>
                                <img src="../Img/placeholder.png" alt="" className="test-img"/>
                                </div>
                                <div className="test-text pl-2">
                                      <p className="text8">Medhub has been so helpful to me, it saves me the stress of going to the hospital</p>
                                      <p className="text9">Carl Jordan</p>
                                      <p className="text10">User, Platform</p>
                                  </div>
                                
                              </div>
                         </div>
                         <div className="test-card">
                              <div className="test-content">
                                <div>
                                <img src="../Img/placeholder.png" alt="" className="test-img"/>
                                </div>
                                <div className="test-text pl-2">
                                      <p className="text8">Medhub has been so helpful to me, it saves me the stress of going to the hospital</p>
                                      <p className="text9">Carl Jordan</p>
                                      <p className="text10">User, Platform</p>
                                  </div>
                                
                              </div>
                         </div>
                         
                         <div className="test-card">
                              <div className="test-content">
                                <div>
                                <img src="../Img/placeholder.png" alt="" className="test-img"/>
                                </div>
                                <div className="test-text pl-2">
                                      <p className="text8">Medhub has been so helpful to me, it saves me the stress of going to the hospital</p>
                                      <p className="text9">Carl Jordan</p>
                                      <p className="text10">User, Platform</p>
                                  </div>
                                
                              </div>
                         </div>
                         <div className="test-card">
                              <div className="test-content">
                                <div>
                                <img src="../Img/placeholder.png" alt="" className="test-img"/>
                                </div>
                                <div className="test-text pl-2">
                                      <p className="text8">Medhub has been so helpful to me, it saves me the stress of going to the hospital</p>
                                      <p className="text9">Carl Jordan</p>
                                      <p className="text10">User, Platform</p>
                                  </div>
                                
                              </div>
                         </div>
        </Carousel>
      )
    }
  }