import React from "react"
import "./notification.css"
import Sidebar from "../Sidebar/side"
//import Navbar from "../Navbar/nav"
export default class Notifications extends React.Component{
    render(){
        return(
            <div>    
                <main className="row-section">
                   <div className="side">
                       <Sidebar/>
                   </div>
                    <div className="main">
                     <div className="mainbox">
                     <div className="sub-title">
                            <h5>Notifications</h5>
                        </div>
                        <div className="notification-cards">
                            <div className="card text-left notification-card">
                              <div className="card-body pl-0">
                                 <div className="row ml-0 mr-0">
                                     <div className="notification-image">
                                         <img src="./Img/cardi.png" alt=""/>
                                     </div>
                                     <div className="notification-content col">
                                        <div><span className="mention-author">Cardi B</span> commented on your post <span className="personal-mention">@perry_tyler</span> </div> 
                                        <div className="reply">okkrrr, that's how I pronounce it</div>
                                     </div>
                                 </div>
                              </div>
                            </div>
                            <div className="card text-left notification-card">
                              <div className="card-body pl-0">
                                 <div className="row ml-0 mr-0">
                                     <div className="notification-image">
                                         <img src="./Img/snoop.png" alt=""/>
                                     </div>
                                     <div className="notification-content col">
                                        <div><span className="mention-author">Snoop Dog</span> retweeted your post <span className="personal-mention">@perry_tyler</span> </div> 
                                        <div className="reply">Balanced Diet don`t really mean anything.</div>
                                     </div>
                                 </div>
                              </div>
                            </div>
                            <div className="card text-left notification-card">
                              <div className="card-body pl-0">
                                 <div className="row ml-0 mr-0">
                                     <div className="notification-image">
                                         <img src="./Img/cardi.png" alt=""/>
                                     </div>
                                     <div className="notification-content col">
                                        <div><span className="mention-author">Cardi B</span> commented on your post <span className="personal-mention">@perry_tyler</span> </div> 
                                        <div className="reply">okkrrr, that's how I pronounce it</div>
                                     </div>
                                 </div>
                              </div>
                            </div>
                            <div className="card text-left notification-card">
                              <div className="card-body pl-0">
                                 <div className="row ml-0 mr-0">
                                     <div className="notification-image">
                                         <img src="./Img/snoop.png" alt=""/>
                                     </div>
                                     <div className="notification-content col">
                                        <div><span className="mention-author">Snoop Dog</span> retweeted your post <span className="personal-mention">@perry_tyler</span> </div> 
                                        <div className="reply">Balanced Diet don`t really mean anything.</div>
                                     </div>
                                 </div>
                              </div>
                            </div>
                            <div className="card text-left notification-card">
                              <div className="card-body pl-0">
                                 <div className="row ml-0 mr-0">
                                     <div className="notification-image">
                                         <img src="./Img/cardi.png" alt=""/>
                                     </div>
                                     <div className="notification-content col">
                                        <div><span className="mention-author">Cardi B</span> commented on your post <span className="personal-mention">@perry_tyler</span> </div> 
                                        <div className="reply">okkrrr, that's how I pronounce it</div>
                                     </div>
                                 </div>
                              </div>
                            </div>
                            <div className="card text-left notification-card">
                              <div className="card-body pl-0">
                                 <div className="row ml-0 mr-0">
                                     <div className="notification-image">
                                         <img src="./Img/snoop.png" alt=""/>
                                     </div>
                                     <div className="notification-content col">
                                        <div><span className="mention-author">Snoop Dog</span> retweeted your post <span className="personal-mention">@perry_tyler</span> </div> 
                                        <div className="reply">Balanced Diet don`t really mean anything.</div>
                                     </div>
                                 </div>
                              </div>
                            </div>
                        </div>
                     </div>
                        </div>        
                </main>
            </div>
        )
    }
}