import React from "react"
import "./explore.css"
import Sidebar from "../Sidebar/side"
import Hospitals from "../Hospitals/Hospital"
//import Navbar from "../Navbar/nav"
//Explore is nearby hospitals in the design

export default class Explore extends React.Component{
    render(){
        return(
            <div>    
                <main className="row-section">
                   <div className="side">
                       <Sidebar/>
                   </div>
                    <div className="main">
                     <div className="mainbox hospital">
                         <div>
                             <Hospitals />
                         </div>
                     </div>
                        </div>        
                </main>
            </div>
        )
    }
}