import React from "react"


export default class Categories extends React.Component{
    render(){
        return(
            <div>
                <div className="sidebox">
                    <div className="side-title">
                        Categories  <span className="float-right"><img src="./Img/list.svg" alt=""/> </span>
                    </div>
                    <div className="dp-line"></div>
                    <div className="sidebox-content">
                        <label className="custom-check">Therapy
                        <input type="checkbox"/>
                        <span className="checkmark"></span>
                        </label>
                        <label className="custom-check">Mental Health
                        <input type="checkbox"/>
                        <span className="checkmark"></span>
                        </label>
                        <label className="custom-check">Daily Tips
                        <input type="checkbox"defaultChecked />
                        <span className="checkmark" ></span>
                        </label>
                        <label className="custom-check">Exercise
                        <input type="checkbox"/>
                        <span className="checkmark"></span>
                        </label>
                        <label className="custom-check">Fatigue
                        <input type="checkbox" defaultChecked/>
                        <span className="checkmark"></span>
                        </label>
                        <label className="custom-check">I.C.U
                        <input type="checkbox"defaultChecked />
                        <span className="checkmark"></span>
                        </label>
                        <label className="custom-check">Demo
                        <input type="checkbox"/>
                        <span className="checkmark"></span>
                        </label>

                    </div>
                </div>
            </div>
        )
    }
}