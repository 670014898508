  
import React, {Component} from "react"
import AOS from 'aos';
import 'aos/dist/aos.css'; 

import "./landing.css"
import "./landing-resp.css"
import Testimonial from "./testimonial"
//import Affliation from "./affliation"
import Header from "./header"
import FOOTER from "./footer"
AOS.init();

export default class LandingPage extends Component {
    constructor() {
        super();
        this.state = {
          show: false,
        };
      }
    render(){
        return(
            <div className="Landing">
                   <Header></Header>
          
             {/*sec 1*/}
             <div className="sec1" id="home">
             <div className="heart4"  >
                     <img src="../Img/heart.svg" alt=""/>
                   </div>
                   <div className="heart5" data-aos="zoom-in"  data-aos-duration="100" data-aos-delay="0" data-aos-easing="ease-in-out">
                     <img src="../Img/heart.svg" alt=""/>
                   </div>
           <div className="row ml-0 mr-0 pt-4 pt-sm-1" >
               <div className="col-lg-7 pl-0 pr-0 text-left pb-5 land2">
               <div className="heart6" data-aos="zoom-in"  data-aos-duration="1000"  data-aos-delay="100" data-aos-easing="ease-in-out">
                     <img src="../Img/heart.svg" alt=""/>
                   </div>
                   <div className="heart7" data-aos="zoom-up"  data-aos-duration="1000" data-aos-delay="100" data-aos-easing="ease-in-out">
                     <img src="../Img/heart.svg" alt=""/>
                   </div>
                       <h3 className="title1">Nothing matters to us like being healthy...</h3>
                       <p className="text1">Are you a Physician, Surgeon, therapist?, medically Inclined? or anybody at all.</p>
                       <button className=" btn btn1 text-white">Join us <span className="arrow pl-3"><img src="../Img/arrow-right.svg" alt=""/></span> </button>
               </div>
               <div className="col-lg-5 pl-0 pr-0 land1  ">
               <div className="heart1" data-aos="fade-right"  data-aos-duration="1000"  data-aos-delay="100" data-aos-easing="ease-in-out">
                     <img src="../Img/heart.svg" alt=""/>
                   </div>
                   <div className="heart2" data-aos="zoom-in"  data-aos-duration="1000"  data-aos-delay="100" data-aos-easing="ease-in-out">
                     <img src="../Img/heart.svg" alt=""/>
                   </div>
                   <div className="heart3" data-aos="zoom-in"  data-aos-duration="1000"  data-aos-delay="100" data-aos-easing="ease-in-out">
                     <img src="../Img/heart.svg" alt=""/>
                   </div>
              <div className="text-center">
                   <img src="../Img/land1.svg" alt="" style={{width: "100%"}} className="img-fluid pt-5 img1"  data-aos="zoom-in"  data-aos-offset="200"  data-aos-delay="1000"  data-aos-duration="1000"  data-aos-easing="ease-in-out"/><br/>
                   </div>
                   <button className=" btn btn-2 text-white">Join us <span className="arrow"><img src="../Img/arrow-right.svg" alt=""/></span> </button>
               </div>
           </div>
           </div>
           {/*sec 2 */}
           <div className="rect"></div>
           <div className="sec2">
               <div className="row ml-0 mr-0">
                   <div className="img-align text-center ml-0 pl-0" data-aos="fade-up"  data-aos-offset="200"  data-aos-delay="50"  data-aos-duration="1000"  data-aos-easing="ease-in-out">
                       <img src="../Img/doc.png" alt="" className="img-fluid " style={{width: "100%"}} />
                   </div>
                   <div className="col-lg-6 text-left sub-sec-2 ml-auto pr-0 " >
                       <p className="text2"> Director, Neurosurgeon, Innovator</p>
                       <h3 className="title2">Mental Health is key to good health, voice out on our platform today. </h3>
                       <p className="text3">On our platform, you can connect with differnt people doctors and non-doctors, Treat and get treated without visiting the hospital, get support and sponsorship at  no cost ($0).</p>
                       <button className=" btn btn2 text-white">Voice out now <span className="arrow pl-3"><img src="../Img/arrow-right.svg" alt=""/></span> </button>
                   </div>
               </div>
           </div>
           {/*sec 3*/}
                  <div className="sec3" id="services">
                      <h3 className="text4">Our Services</h3>
                      <div className="sub-sec-3 ">
                          <div className="card one text-center"   data-aos="zoom-in" data-aos-delay="50" data-aos-duration="1000" >
                              <img className="img-fluid" src="../Img/bar.svg" alt=""  max-width="100%"/>
                              <div className="card-body pl-0 pr-0">
                                  <h4 className="card-title">Virtual Treatment</h4>
                                  <p className="card-text">Book an appointment any kind of doctor instead of going to the hospital.</p>
                                  <div className="text-right pt-4 mt-4">
                                  <button className="btn learn-more text-white">Learn more<span className="arrow pl-4"><img src="../Img/arrow-right.svg" alt=""/></span> </button>
                                  </div>
                              </div>
                          </div>
                          <div className="card two text-center"  data-aos="zoom-in" data-aos-delay="50" data-aos-duration="1000" >
                              <img className="img-fluid" src="../Img/globe.svg" alt=""/>
                              <div className="card-body pl-0 pr-0">
                                  <h4 className="card-title">Daily Tips</h4>
                                  <p className="card-text">Learn how to live a healthy life by following tips from your favourite doctors around the globe.</p>
                                  <div className="text-right pt-3">
                                  <button className="btn learn-more text-white">Learn more<span className="arrow pl-4"><img src="../Img/arrow-right.svg" alt=""/></span> </button>
                                  </div>
                              </div>
                          </div>
                          <div className="card three text-center" data-aos="zoom-in" data-aos-delay="50" data-aos-duration="1000" >
                              <img className="img-fluid" src="../Img/house.svg" alt=""/>
                              <div className="card-body pl-0 pr-0">
                                  <h4 className="card-title">Purchase Products</h4>
                                  <p className="card-text">Purchase priscribed drugs from online stores, you would get redirected to.</p>
                                  <div className="text-right pt-1">
                                  <button className="btn learn-more text-white mt-4">Learn more<span className="arrow pl-4"><img src="../Img/arrow-right.svg" alt=""/></span> </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/*sec 4*/}
                  <div className="sec4" id="about">
                  <div className="bg-img">
                  <h3 className="text5">About Us</h3>
                  <img src="../Img/about.svg" style={{width: "100%"}} alt="" className="img-fluid ota mb-2"  data-aos="zoom-in"  data-aos-offset="200"  data-aos-delay="50"  data-aos-duration="1000"  data-aos-easing="ease-in-out"/>
                  <div className="row container-fluid">

                      <div className="col-lg-6 text-left">
                          <h3 className="title3">Medhub is a renowed platform that is tested and trusted by millions.</h3>
                          <p className="text6">We are one of the most renowed platfoem with over 3 years of experience in the practicing medicine.
We have hands on medical personelles to make sure you get great medical advice and your needs are met. Your health is our priority.</p>
<button className=" btn btn1 text-white">Learn More <span className="arrow pl-3"><img src="../Img/arrow-right.svg" alt=""/></span> </button>
                      </div>
                      <div className="col-lg-6 about-img"  data-aos="fade-up"  data-aos-offset="200"  data-aos-delay="50"  data-aos-duration="1000"  data-aos-easing="ease-in-out">
                          <img src="../Img/about.svg" alt="" className="img-fluid"/>
                      </div>
                  </div>
                  </div>
                  </div>
           {/*sec 5*/}
                   <div className="sec5" id="contact">
                       <h3 className="text7 text-center">Contact Us </h3>
                       <div className="row ml-0 mr-0 pt-4">
                           <div className="contact-image  pt-4 text-center">
                                <img src="../Img/contact.svg" alt="" style={{width: "100%"}} className="img-fluid contact-img mb-2 " data-aos="zoom-in"  data-aos-offset="200"  data-aos-delay="50"  data-aos-duration="1000"  data-aos-easing="ease-in-out"/>
                           </div>
                           <div className="col-lg-6  sub-sec-4 ml-auto pl-0 pr-0">
                               <form className="form-group text-left text-white mt-2">
                                 <label htmlFor="name">Name</label>
                                 <input type="text" className="form-control mb-3" name="" id="name" placeholder="Full Name"/>
                                 <label htmlFor="email">Email</label>
                                 <input type="email" className="form-control mb-3" name="" id="email"  placeholder="Email Address"/>
                                 <label htmlFor="message">Message</label>
                                <textarea className="form-control mb-3" name="" id="message" placeholder="Type your message here"></textarea>
                                <div className=" text-center big ">
                                    <button className="btn submit-btn " type="submit">Submit</button>
                                </div>
                               </form>
                           </div>
                       </div>
                   </div>
            {/*sec 6*/}
                  <div className="sec6 pt-5 pb-5" id="testimonial">
                      <Testimonial/>
                  </div>
            {/*sec 7*/}
            {/*
                  <div className="sec7  pb-5" id="affliation">
                      <h3 className="title4 pb-4">Our Affliation</h3>
                      <div className=" align-items-center m-auto">
                     <Affliation/>
                      </div>
                  </div>
                   */}
               <img src="../Img/slope.png" alt="" className="img-fluid footer-slope"></img>
             <FOOTER/>
       
        

            </div>
        )
    }
}