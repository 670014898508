import React from "react"
import "./trends.css"
import Sidebar from "../Sidebar/side"
//import Navbar from "../Navbar/nav"
export default class Trends extends React.Component{
    render(){
        return(
            <div>    
                <main className="row-section">
                   <div className="side">
                       <Sidebar/>
                   </div>
                    <div className="main">
                     <div className="mainbox">
                        <div className="sub-title">
                            <h5>Trends for you</h5>
                        </div>
                        <div className="trend-img">
                        
                            <div className="trend-img-text">
                                 <h6 className="trend-author-name">Dr Ben Carson<img src="./Img/verifiedw.svg" alt=""/> <span className="trend-time">5 hours ago</span> </h6>
                                 <p className="trend-reason">Wins Neurosurgeon of the year again at medic awards.</p>
                            </div>
                        </div>
                        <div className="trend-cards">
                            <div className="card text-left trend-card">
                              <div className="trend-card-body">
                                  <p className="trend-location">Trending in  UK</p>
                                <h4 className="trend-name">Daily Motivation</h4>
                                <p className="trend-mention">3215 mentions </p>
                              </div>
                            </div>
                            <div className="card text-left trend-card">
                              <div className="trend-card-body">
                                  <p className="trend-location">Trending in Nigeria</p>
                                <h4 className="trend-name">Balanced Diet</h4>
                                <p className="trend-mention">3215 mentions </p>
                              </div>
                            </div>
                            <div className="card text-left trend-card">
                              <div className="trend-card-body">
                                  <p className="trend-location">Trending in  UK</p>
                                <h4 className="trend-name">Daily Motivation</h4>
                                <p className="trend-mention">3215 mentions </p>
                              </div>
                            </div>
                            <div className="card text-left trend-card">
                              <div className="trend-card-body">
                                  <p className="trend-location">Trending in Nigeria</p>
                                <h4 className="trend-name">Balanced Diet</h4>
                                <p className="trend-mention">3215 mentions </p>
                              </div>
                            </div>
                        </div>
                     </div>
                        </div>        
                </main>
            </div>
        )
    }
}