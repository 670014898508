import React from "react"

export default class Suggestion extends React.Component{
    render(){
        return(
            <div>
                      <div className="sidebox">
                    <div className="side-title">
                       Suggestions  <span className="float-right"><img src="./Img/suggestion.svg" alt=""/> </span>
                    </div>
                    <div className="dp-line"></div>
                    <div className="sidebox-content">
                        <div className="row ml-0 mr-0 side-row">
                        <div className="image ">
                          <img src="./Img/mano.png" alt=""></img>
                      </div>
                      <div className="user-details  col">
                          <p className="name">Jones Jonathan <img src="./Img/verified.svg" alt=""/> </p>
                          <p className="post">Optician</p>
                      </div>
                      <div className="sign">
                          <img src="./Img/sign.svg" alt=""/>
                      </div>
                        </div>
                        <div className="row ml-0 mr-0 side-row">
                        <div className="image ">
                          <img src="./Img/manoo.png" alt=""></img>
                      </div>
                      <div className="user-details  col">
                          <p className="name">Tony Codor <img src="./Img/verified.svg" alt=""/> </p>
                          <p className="post">Dentist</p>
                      </div>
                      <div className="sign">
                          <img src="./Img/sign.svg" alt=""/>
                      </div>
                        </div>
                        <div className="row ml-0 mr-0 side-row">
                        <div className="image ">
                          <img src="./Img/woman.png" alt=""></img>
                      </div>
                      <div className="user-details  col">
                          <p className="name">Keisha Raymond <img src="./Img/verified.svg" alt=""/> </p>
                          <p className="post">Surgeon</p>
                      </div>
                      <div className="sign">
                          <img src="./Img/sign.svg" alt=""/>
                      </div>
                        </div>
                        <div className="row ml-0 mr-0 side-row">
                        <div className="image ">
                          <img src="./Img/beut.png" alt=""></img>
                      </div>
                      <div className="user-details  col">
                          <p className="name hasi">Laura Phillips </p>
                          <p className="post">User</p>
                      </div>
                      <div className="sign">
                          <img src="./Img/sign.svg" alt=""/>
                      </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}