import React from 'react'
// importing css
import "./Hospital.css"
// importing images
import Hospital1 from "../Hospitals/Images/hj 1.png"

 const Hospital = () => {
    return (
        <div>
            <div className="hosp-heading">
           <h3 className="head-text">Nearby Hospitals</h3>
           </div>
           
           <div className="hospital-container">
               <hr/>
           <div className="hospital-info">
              <div className="hospital-image"><img src={Hospital1} alt="" /> </div> 
              <div className="hospital-info-text">
                  <h5>John Hopkins teaching Hospital, Oklahoma, Usa</h5>
                  <div>
                  <p>
                      Therapy,neurosurgery.
                      </p>
                  </div>
                  <div className="hospital-info-split">
                      <div className="hospital-desc-location">
                      <p>
                      5 miles away
                      </p>
                      </div>
                      <div className="appointment-button">
                          <button className="hosp-button">Book Appointment</button>
                      </div>
                  </div>
                  </div>
              </div>
              <hr/>
              <div className="hospital-info">
              <div className="hospital-image"><img src={Hospital1} alt="" /> </div> 
              <div className="hospital-info-text">
                  <h5>St theressa memorial hospital, Pennyslyvia, Usa</h5>
                  <p>
                      Sugery,Cardiology.
                      </p>
                  <div className="hospital-info-split">
                      <div className="hospital-desc-location">
                      <p>
                      24 miles away
                      </p>
                      </div>
                      <div className="appointment-button">
                          <button className="hosp-button">Book Appointment</button>
                      </div>
                  </div>
                  </div>
              </div>
              <hr/>
              <div className="hospital-info">
              <div className="hospital-image"><img src={Hospital1} alt="" /> </div> 
              <div className="hospital-info-text">
                  <h5>St theressa memorial hospital, Pennyslyvia, Usa</h5>
                  <div>
                  <p>
                      Sugery,Cardiology.
                      </p>
                  </div>
                  <div className="hospital-info-split">
                      <div className="hospital-desc-location">
                      <p>
                      24 miles away
                      </p>
                      </div>
                      <div className="appointment-button">
                          <button className="hosp-button">Book Appointment</button>
                      </div>
                  </div>
                  </div>
              </div>
              <hr/>
              <div className="hospital-info">
              <div className="hospital-image"><img src={Hospital1} alt="" /> </div> 
              <div className="hospital-info-text">
                  <h5>Dave and Jones hospital, philadephia, Usa</h5>
                  <div><p>
                      Dentistry, Dermatology
                      </p> </div>
                  <div className="hospital-info-split">
                      <div className="hospital-desc-location">
                      <p>
                      10 miles away
                      </p>
                      </div>
                      <div className="appointment-button">
                          <button className="hosp-button">Book Appointment</button>
                      </div>
                  </div>
                  </div>
              </div>
              <hr/>
              <div className="hospital-info">
              <div className="hospital-image"><img src={Hospital1} alt="" /> </div> 
              <div className="hospital-info-text">
                  <h5>Andrew Pharmaceuticals,Atlanta, Usa</h5>
                  <div>
                  <p>
                      Dealers in all kind of stimulants
                      </p>
                  </div>
                  <div className="hospital-info-split">
                      <div className="hospital-desc-location">
                      <p>
                      1.5 miles away
                      </p>
                      </div>
                      <div className="appointment-button">
                          <button className="hosp-button">Book Appointment</button>
                      </div>
                  </div>
                  </div>
              </div>
              <hr/>
              <div className="hospital-info">
              <div className="hospital-image"><img src={Hospital1} alt="" /> </div> 
              <div className="hospital-info-text">
                  <h5>John Hopkins teaching Hospital, Oklhoma, Usa</h5>
                  <div>
                  <p>
                      Therapy,neurosurgery.
                      </p>
                  </div>
                  <div className="hospital-info-split">
                      <div className="hospital-desc-location">
                      <p>
                      5 miles away
                      </p>
                      </div>
                      <div className="appointment-button">
                          <button className="hosp-button">Book Appointment</button>
                      </div>
                  </div>
                  </div>
              </div>
              <hr/>
              <div className="hospital-info">
              <div className="hospital-image"><img src={Hospital1} alt="" /> </div> 
              <div className="hospital-info-text">
                  <h5>John Hopkins teaching Hospital, Oklhoma, Usa</h5>
                  <div>
                  <p>
                      Therapy,neurosurgery.
                      </p>
                  </div>
                  <div className="hospital-info-split">
                      <div className="hospital-desc-location">
                      <p>
                      10 miles away
                      </p>
                      </div>
                      <div className="appointment-button">
                          <button className="hosp-button">Book Appointment</button>
                      </div>
                  </div>
                  </div>
              </div>
              <hr/>
              <div className="hospital-info">
              <div className="hospital-image"><img src={Hospital1} alt="" /> </div> 
              <div className="hospital-info-text">
                  <h5>Andrew Pharmaceuticals,Atlanta, Usa</h5>
                  <div>
                  <p>
                      Dealers in all kind of stimulants
                      </p>
                  </div>
                  <div className="hospital-info-split">
                      <div className="hospital-desc-location">
                      <p>
                      16 miles away
                      </p>
                      </div>
                      <div className="appointment-button">
                          <button className="hosp-button">Book Appointment</button>
                      </div>
                  </div>
                  </div>
              </div>
              <hr/>
              <div className="hospital-info">
              <div className="hospital-image"><img src={Hospital1} alt="" /> </div> 
              <div className="hospital-info-text">
                  <h5>St theressa memorial hospital, Pennyslyvia, Usa</h5>
                  <div><p>
                      Sugery,Cardiology.
                      </p></div>
                  <div className="hospital-info-split">
                      <div className="hospital-desc-location">
                      <p>
                      24 miles away
                      </p>
                      </div>
                      <div className="appointment-button">
                          <button className="hosp-button">Book Appointment</button>
                      </div>
                  </div>
                  </div>
              </div>
               </div> 
        </div>
    )
}

export default Hospital