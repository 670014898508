import React from "react"
import "./side.css"
import Categories from "../Categories/categories"
import FIND_A_DOC from "../Find_doc/find.js"
import Suggestion from "../Suggestions/suggestion"

export default class Sidebar extends React.Component{
    render(){
        return(
            <div>
                <Categories/>
            <FIND_A_DOC/>
          <Suggestion/>
            </div>
        )
    }
}