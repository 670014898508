import React from "react"
import "./home.css"
import Sidebar from "../Sidebar/side"
import {Link} from "react-router-dom"
//import Navbar from "../Navbar/nav"
export default class Home extends React.Component{
    render(){
        return(
            <div>    
                <main className="row-section">
                   <div className="side">
                       <Sidebar/>
                   </div>
                    <div className="main">
                        <div className="mainbox">
                 {/*}           <div className="d-flex justify-content-between align-items-center ml-0 mr-0 story">
                                <div className="story-img">
                                    <img src="./Img/john.png" alt=""/>
                                    <p>John Doe</p>
                                </div>
                                <div className="story-img">
                                    <img src="./Img/cardi.png" alt=""/>
                                    <p>Cardi</p>
                                </div>
                                <div className="story-img">
                                    <img src="./Img/tyga.png" alt=""/>
                                    <p>Tyga</p>
                                </div>
                                <div className="story-img">
                                    <img src="./Img/snoop.png" alt=""/>
                                    <p>Snoop Dog</p>
                                </div>
                                <div className="story-img">
                                    <img src="./Img/nicki.png" alt=""/>
                                    <p>Nicki</p>
                                </div>
                                <div className="story-img">
                                    <img src="./Img/dababy.png" alt=""/>
                                    <p>Dababy</p>
                                </div>
                            </div>
        */}
                            <div className="content-box">
                                <div className="card text-left content-card">
                                <div className="">
                                 
                                    <div className="post-details ">
                                        <div className="author-details row ml-0 mr-0">
                                        <div className="author-img pr-1">
                                        <img src="./Img/woman.png" alt="" />
                                    </div>
                                            <div className="col resp">
                                        <div className="author-name">Perry Tyler  </div>
                                        <Link to="/post">  <div className="post-title">Balanced Diet  <span className="hashtag">#diet #Daily Tips</span> </div></Link>
                                        </div>
                                        </div>
                                        <div className="break">
                                        <div >
                                            <img src="./Img/card-img.png" alt=""className="post-img card-img"/>
                                        </div>
                                        <div className="post-content">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget risus id est congue congue. Sed eu venenatis sem. Vivamus ultricies imperdiet dui, ac blandit magna aliquet eu. Integer laoreet dui risus, eu facilis.
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center ml-0 mr-0 reaction">
                                           <div className="like-post">
                                               <img src="./Img/like.svg" alt=""/>
                                           </div>
                                           <div className="repost">
                                               <img src="./Img/cached.svg" alt=""/>
                                           </div>
                                           <div className="comment">
                                               <img src="./Img/comment.svg" alt=""/>
                                           </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="card text-left content-card">
                                <div className="">
                                 
                                    <div className="post-details ">
                                        <div className="author-details row ml-0 mr-0">
                                        <div className="author-img pr-1">
                                        <img src="./Img/woman.png" alt="" />
                                    </div>
                                            <div className="col resp">
                                        <div className="author-name">Perry Tyler  </div>
                                        <Link to="/post">  <div className="post-title">Balanced Diet  <span className="hashtag">#diet #Daily Tips</span> </div></Link>
                                        </div>
                                        </div>
                                        <div className="break">
                                        <div >
                                            <img src="./Img/card-img.png" alt=""className="post-img card-img"/>
                                        </div>
                                        <div className="post-content">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget risus id est congue congue. Sed eu venenatis sem. Vivamus ultricies imperdiet dui, ac blandit magna aliquet eu. Integer laoreet dui risus, eu facilis.
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center ml-0 mr-0 reaction">
                                           <div className="like-post">
                                               <img src="./Img/like.svg" alt=""/>
                                           </div>
                                           <div className="repost">
                                               <img src="./Img/cached.svg" alt=""/>
                                           </div>
                                           <div className="comment">
                                               <img src="./Img/comment.svg" alt=""/>
                                           </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="card text-left content-card">
                                <div className="">
                                 
                                    <div className="post-details ">
                                        <div className="author-details row ml-0 mr-0">
                                        <div className="author-img pr-1">
                                        <img src="./Img/woman.png" alt="" />
                                    </div>
                                            <div className="col resp">
                                        <div className="author-name">Perry Tyler  </div>
                                        <Link to="/post">  <div className="post-title">Balanced Diet  <span className="hashtag">#diet #Daily Tips</span> </div></Link>
                                        </div>
                                        </div>
                                        <div className="break">
                                        <div >
                                            <img src="./Img/card-img.png" alt=""className="post-img card-img"/>
                                        </div>
                                        <div className="post-content">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget risus id est congue congue. Sed eu venenatis sem. Vivamus ultricies imperdiet dui, ac blandit magna aliquet eu. Integer laoreet dui risus, eu facilis.
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center ml-0 mr-0 reaction">
                                           <div className="like-post">
                                               <img src="./Img/like.svg" alt=""/>
                                           </div>
                                           <div className="repost">
                                               <img src="./Img/cached.svg" alt=""/>
                                           </div>
                                           <div className="comment">
                                               <img src="./Img/comment.svg" alt=""/>
                                           </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                   
                        </div>        
                </main>
            </div>
        )
    }
}