import React, { useState } from "react";
import { ReactComponent as CloseMenu } from "./Img/x.svg";
import { ReactComponent as MenuIcon } from "./Img/menu.svg"

import "./header.css";

const Header  = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);


    return (
      <div className="Header">
        <nav className="my-navbar land-nav ">
       <a className="my-nav-logo " href="#!"><img src="../Img/logo.svg" alt=""/><span className="medhub pl-1">MEDHUB</span></a>
             <button className="my-nav-icon btn" onClick={handleClick}>
          {click ? (
            <CloseMenu className="my-menu-icon" />
          ) : (
            <MenuIcon className="my-menu-icon" />
          )}
        </button>
    <div className={click ? "my-nav active" : "my-nav"}>
      <ul className="my-nav-list  ">
      <li className="my-nav-item">
                <a className="my-nav-link" href="#home">Home</a>
            </li>
            <li className="my-nav-item">
                <a className="my-nav-link" href="#!">Products</a>
            </li>
            <li className="my-nav-item active">
                <a className="my-nav-link" href="#!">Blog </a>
            </li>
            <li className="my-nav-item">
                <a className="my-nav-link" href="#contact">Contact Us</a>
            </li>
            <li className='my-nav-item'>
            <div className="dropdown">
                 <button className="dropbtn"><img src="../Img/arrow-down.svg" alt=""/></button>
                  <div className="dropdown-content">
                  <a href="#!">Sign In</a>
                  <a href="#!">Sign Up</a>
                
            </div>
            </div>
            </li>
      </ul>
    </div>
        </nav>
      </div>
    );
  };
  
  export default Header;
  